module.exports = {
    "GetRepos" : () => `
        query {
          allDirectory(filter: {relativeDirectory: {eq: "repos"}}) {
            nodes {
              name
              relativeDirectory
              relativePath
            }
          }
        }
    `,
    "GetKataGroups" : (repoName) => `
        query {
          allDirectory(filter: {relativeDirectory: {eq: "repos/${repoName}"}}) {
            nodes {
              name
              relativeDirectory
              relativePath
            }
          }
        }
    `,
    "GetKatas" : (repoName, kataGroupName) => `
        query {
          allFile(filter: {relativeDirectory: {eq: "repos/${repoName}/${kataGroupName}"}}) {
            nodes {
              dir
              absolutePath
              name
              relativeDirectory
              relativePath
            }
          }
        }

    `
}
