import * as React from "react"; 
import { graphql, Link } from "gatsby";
import * as QueryBuilder from "../common/queryBuilder.js";
import Layout from "../components/layout.js";

function mapDataToLink(data)
{
    const nodes = data.allFile.nodes.map(node => {
        const { name } = node;
        return {
            to : `./${name}`,
            name : node.name
        };
    });

    const elements = nodes.map((node, index) => {
        const { to, name } = node;

        return (
            <li key={index}>
                <Link to={to}> {name} </Link>
            </li>
        );
    });

    return elements;
}

export default function Repo({ data })
{
    const links = mapDataToLink(data);

    return (
        <Layout>
            <div> 
                This is an overview of all the katas in this repo
                <h1> Pages: </h1>
                <ul>
                    { links }
                </ul>
            </div>
        </Layout>
    );
}

export const query = graphql`
query($relativeDirectory : String!){
  allFile(filter: {relativeDirectory: {eq: $relativeDirectory}}) {
    nodes {
      dir
      absolutePath
      name
      relativeDirectory
      relativePath
    }
  }
}
`;
